import React from 'react'
import { graphql } from 'gatsby'

import Root from '../components/Root'
import Meta from '../components/Meta'
import CareerHero from '../components/CareerHero'
import CareerBody from '../components/CareerBody'

export const query = graphql`
	query getJob($id:String, $department:String) {
		relatedCareers: allWorkableJob(filter: {department: {eq: $department}, id: { ne:$id }}, limit: 5) {
			edges {
				node {
					id
					application_url
					benefits
					department
					description
					employment_type
					# experience
					requirements
					shortcode
					title
				}
			}
		}
		career: workableJob(id: {eq: $id}) {
			id
			application_url
			benefits
			department
			description
			employment_type
			# experience
			requirements
			shortcode
			title
		}
	}
`

const CareerTemplate = ({ data }) => {
	const career = data?.career
	const seo = career?.seo
	const relatedCareers = data?.relatedCareers?.edges || []

	return (
		<Root>
			<Meta { ...{ seo } } />
			<CareerHero { ...{ ...career } } />
			<CareerBody { ...{ ...career, relatedCareers } } />
		</Root>
	)
}

export default CareerTemplate